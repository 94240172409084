import Vue from 'vue'
import axios from 'axios'
import { apiPath } from './../../config'
import xrc from 'x-result-count'
import qs from 'qs'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {},
      list: [],
      page: {
        skip: null,
        count: null,
        total: null
      }
    }
  },
  mutations: {
    updateEventsResultItem: (state, { item }) => {
      if (state.items[item.id]) Vue.set(state.items, item.id, item)
    },
    deleteEventsResultItem: (state, { item }) => {
      if (state.items[item.id]) Vue.set(state.items, item.id, null)
    },
    findEventsResult: (state, { page, data }) => {
      Object.assign(state.page, page)
      state.list.splice(0, state.list.length, ...data.map(entry => entry.id))
      data.forEach(entry => Vue.set(state.items, entry.id, entry))
    }
  },
  getters: {
    eventList: ({ list, items }) => list.map(id => items[id]).filter(n => n != null)
  },
  actions: {
    findEvents: async ({ commit, getters: { affiliateId } }, { skip = 0, limit = 20, sort, status, since, until, eventSeriesId, eventSetId, promotionId, showCurrentMultiday, days, timeSince, timeUntil }) => {
      const query = qs.stringify({ skip, limit, sort, status, since, until, affiliateId, eventSeriesId, eventSetId, promotionId, showCurrentMultiday, days, timeSince, timeUntil }, { skipNulls: true })
      const { data, headers } = await axios.get(`${apiPath}/event?${query}`)
      const page = xrc.parse(headers['x-result-count'])
      commit('findEventsResult', { page, data })
      return { page, data }
    }
  }
}
