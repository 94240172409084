import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'
import xrc from 'x-result-count'
import qs from 'qs'

export default {
    namespaced: false,
    state: () => {
        return {
            items: {},
            list: [],
            page: {
                skip: null,
                count: null,
                total: null
            }
        }
    },
    mutations: {
        findBundlesResult: (state, { page, data }) => {
          Object.assign(state.page, page)
          state.list.splice(0, state.list.length, ...data.map(entry => entry.id))
          data.forEach(entry => Vue.set(state.items, entry.id, entry))
        }
      },
      getters: {
        eventBundles: ({ list, items }) => list.map(id => items[id]).filter(n => n != null)
      },
      actions: {
        findBundles: ({ commit, getters: { affiliateId } }, { skip = 0, limit = 9999, sort, status }) => {
          const query = qs.stringify({ skip, limit, sort, status, affiliateId })
          return axios.get(`${apiPath}/eventSet?${query}`)
            .then(function ({ data, headers }) {
              const page = xrc.parse(headers['x-result-count'])
              commit('findBundlesResult', { page, data })
            })
            .catch(function (error) {
              throw error
            })
        }
      }
}