import Vue from 'vue'
import Vuex from 'vuex'
import access from './access/index'
import additionalAgreements from './additional-agreements/index'
import asset from './asset/index'
import assets from './assets/index'
import auth from './auth/index'
import bundles from './bundles/index'
import config from './config/index'
import contact from './contact/index'
import copy from './copy/index'
import current from './current/index'
import event from './event/index'
import eventReport from './event-report/index'
import eventReservedSeats from './event-reserved-seats/index'
import events from './events/index'
import seatmap from './seatmap/index'
import eventsReport from './events-report/index'
import filter from './filter/index'
import dashboardInfo from './dashboard-info/index'
import facebook from './facebook/index'
import fee from './fee/index'
import global from './global/index'
import update from './update/index'
import gtm from './gtm/index'
import languages from './languages/index'
import location from './location/index'
import locations from './locations/index'
import notification from './notification/index'
import page from './page/index'
import password from './password/index'
import profile from './profile/index'
import promotion from './promotion/index'
import promotions from './promotions/index'
import registration from './registration/index'
import report from './report/index'
import review from './review/index'
import serie from './serie/index'
import collectiveSettlement from './collective-settlement/index'
import newsBanner from './newsbanner/index'
import serieReport from './serie-report/index'
import series from './series/index'
import shopConfig from './shop-config/index'
import settings from './settings/index'
import consentManager from './consent-manager/index'
import VuexPersistence from 'vuex-persist'
import { prefix } from './../config'

import log from 'loglevel'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: `${prefix}_settings`,
  storage: window.localStorage,
  modules: ['settings']
})

let store = new Vuex.Store({
  state: {
    version: process.env.PACKAGE_VERSION,
    builtAt: process.env.BUILT_AT,
    ready: false,
    unavailable: false,
    loading: false,
    error: null
  },
  mutations: {
    error: (state, error) => {
      if (error != null && error._isRouter) {
        log.error('routing error', error)
      } else {
        log.error('error', error)
        state.error = error
      }
    },
    ready: (state, ready) => {
      state.ready = ready === true
    },
    unavailable: (state, unavailable) => {
      state.unavailable = unavailable === true
    },
    loading: (state, loading) => {
      state.loading = loading === true
    }
  },
  actions: {
    init ({ dispatch, commit }) {
      return new Promise((resolve) => {
        Promise.all([
          dispatch('restoreAuth'),
          dispatch('loadConfig'),
          dispatch('loadGlobal')
        ]).then(() => {
          Promise.all([
            dispatch('initLanguage')
          ]).then(() => {
            commit('ready', true)
            resolve()
            dispatch('update/init')
          })
        }).catch((e) => {
          log.error('error during init', e)
          dispatch('initLanguage').then(() => {
            commit('unavailable', true)
            resolve()
          })
        })
      })
    },
    reset ({ commit }) {
      commit('resetAuth')
      commit('resetCurrent')
    }
  },
  plugins: [vuexLocal.plugin],
  modules: {
    access,
    additionalAgreements,
    asset,
    assets,
    auth,
    bundles,
    config,
    contact,
    copy,
    current,
    event,
    eventReport,
    eventReservedSeats,
    events,
    eventsReport,
    dashboardInfo,
    facebook,
    fee,
    filter,
    global,
    gtm,
    languages,
    location,
    locations,
    collectiveSettlement,
    newsBanner,
    notification,
    page,
    password,
    profile,
    promotion,
    promotions,
    registration,
    report,
    review,
    serie,
    serieReport,
    series,
    seatmap,
    shopConfig,
    update,
    settings,
    consentManager
  }
})

export const useStore =  () => store
export default store
